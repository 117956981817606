<template>
  <div>
    <custom-alert v-if="displayAlert==true" :message="alertMessage"
                  v-on:showedAlert="resetAlert" id="alert_box"></custom-alert>
    <div class="page-banner">
       <img :src="this.getSiteProps('clubinnerpages.headerbanner')" style="width:100%;" alt="page-banner">
      <div class="page-title">
        <h5 class="text-white">Instructor/Coach Programs</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-3 mt-4">
        <div class="card card-body pt-3 pb-3 ">
          <p>
            Are you looking to pass on your water ski knowledge to a newcomer?
            Or do you want to help a beginner get to the next level? USA-WSWS
            offers several instructor/coach programs:
          </p>
          <ul>
            <li>
              <p>
                <a @click="openLink"  :href="validMember?'https://usawaterski.digitalchalk.com/auth/login':'javascript:void(0)'" :target="validMember?'_blank':''"><strong>Level 1 Instructor Certification Course ($35)</strong></a> –
                Provides training and certification for aspiring instructors and
                coaches to teach students the necessary skills to safely and
                successfully participate in the various towed water sports.
              </p>
            </li>
            <li>
              <p>
                <strong
                  >Level 1 Adaptive Instructor Certification Course
                  ($35)</strong
                >
                – Learn the necessary skills to safely train and coach water ski
                athletes with disabilities.
              </p>
            </li>
            <li>
              <p>
                <strong>Coaches Liability Insurance Coverage ($60)</strong> -
                USA Water Ski &amp; Wake Sports offers certified
                instructors/coaches the option of purchasing Coaches Liability
                Insurance. This program provides
                <a
                  class="text-danger"
                  href="https://www.teamusa.org/USA-Water-Ski/Insurance/Coaches-Liability-Insurance"
                  target="_blank"
                  >General Liability and Professional Liability coverage</a
                >
                to USA Water Ski &amp; Wake Sports certified
                instructors/coaches.
              </p>
            </li>
          </ul>
          <p>
            Questions? Email
            <a class="text-danger" href="mailto:coaching@usawaterski.org"
              >coaching@usawaterski.org</a
            >
            or call (863) 874-5681
          </p>
        </div>
      </div>
      <div class="col-md-4 status_bar mt-4">
        <div class=" right-sidebarlink">
          <pagesidebar
            :links="this.getSiteProps('memberdashboard.links')"
          ></pagesidebar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pagesidebar from "../components/PageSidebar";
import CustomAlert from "../../../CustomAlert";
import moment from "moment";
import $ from "jquery";

export default {
  name: "instructorcoachprogram",
  components: { CustomAlert, pagesidebar},
  data() {
    return {
        prop_links: null,
      validMember: '',
      isMember: [],
      alertMessage:'',
      displayAlert:'',
    }
  },
  updated() {
    this.prop_links = this.getSiteProps("memberdashboard.links");
  },
  methods: {
    openLink() {
      this.displayAlert = false;
      this.isMember = JSON.parse(localStorage.getItem("member"));
      const validThru = moment(this.isMember.membership_endtate).format('MM/DD/YYYY');
      const currentDate = moment().format('MM/DD/YYYY');
      const isExpired = moment(currentDate).isAfter(validThru);
      const age = this.isMember.age;
      console.log(age);
      if (!isExpired && age >= 18 && this.isMember.reg_membership_id != 7 && this.isMember.reg_membership_id != 3 && this.isMember.reg_membership_id != 4) {
        this.validMember = true;
      } else {
        this.validMember = false;
        this.displayAlert = true;
        this.alertMessage = 'Be at least 18 years of age and a current Active member ' +
            '<br>of USA Water Ski & Wake Sports (Guest, GrassRoots, and Supporting Members are not eligible)';
        this.setDangerBg();

      }
    },
    setDangerBg(){
      setTimeout(() => {
        $('#alert_box').children('div').addClass('alert-danger');
      });
    },
  },
};
</script>